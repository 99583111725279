"use client";

import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";

import { useUserContext } from "@/context/user.context";
import { DataDumpResponse } from "@/types/api";
import { sendPageData } from "@/utils/extension";

const ExtensionProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageData, setPageData] = useState<DataDumpResponse>(); // response from extension
  const { company } = useUserContext();

  const handleMessageEvent = useCallback(
    (e: MessageEvent) => {
      const { data } = e;
      const dom = data.dom;
      const type = data.type;
      if (type === "upwg_pageDataCollected" && company) {
        sendPageData({ companyId: company.id, dom }).then(
          (data: DataDumpResponse) => {
            setPageData(data);
          },
        );
      }
    },
    [company],
  );

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);

    return () => window.removeEventListener("message", handleMessageEvent);
  }, [handleMessageEvent]);

  const router = useRouter();
  useEffect(() => {
    if (pageData) {
      switch (pageData.page) {
        case "all":
          router.push("/");
          break;
        case "candidate": {
          const candidateEmails = pageData.candidateEmails;
          router.push(`/candidates/${encodeURIComponent(candidateEmails[0])}`);
          break;
        }
        case "job": {
          const screenerLocationId = pageData.screenerLocationIds[0];
          router.push(`/screener-location/${screenerLocationId}`);
          break;
        }
        default:
          break;
      }
    }
  }, [pageData, router]);

  return children;
};

export default ExtensionProvider;
